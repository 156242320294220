@import '../grid';

.container {
    margin: 0 auto;
    @include for-all-devices() {
        max-width: grid-col-width($current-grid-size, grid-var('columns'));
    }
    @media all and (min-width: $grid-width-limit) {
        max-width: grid-col-width($current-grid-size, grid-var('columns'), $grid-width-limit);
    }
}
