.display--none {
    display: none;
}

.display--inline {
    display: inline;
}

.display--inline-block {
    display: inline-block;
}

.text-align--left {
    text-align: left;
}

.text-align--right {
    text-align: right;
}

.text-align--center{
    text-align: center;
}

.overflow--scroll {
    overflow: auto;
}

.overflow--scrolly {
    overflow-y: auto;
    word-wrap: break-word;
}

.overflow--scrollx {
    overflow-x: auto;
}

.overflow--hidden {
    overflow: hidden;
}

.nowrap {
    white-space: nowrap;
}
