@import 'breakpoints';

$baseline: 1rem;

$grid-width-limit: 1920px;

/// Map contains all grid constants

$grid: (
    xl: (
        breakpoint: map_get($breakpoints, 'xl'),
        padding: 10rem,
        columns: 8,
        gap: 3rem,
    ),
    lg: (
        breakpoint: map_get($breakpoints, 'lg'),
        padding: 4rem,
        columns: 8,
        gap: 3rem,
    ),
    sm: (
        breakpoint: map_get($breakpoints, 'sm'),
        padding: 4rem,
        columns: 4,
        gap: 2rem,
    ),
    xs: (
        breakpoint: map_get($breakpoints, 'xs'),
        padding: 1.5rem,
        columns: 4,
        gap: 1.5rem,
    ),
);

/// Get value from `$grid` map
/// @param {String} $key  - key of requested value
/// @param {String} $size - device size requested will be given for.
///                         You don't need to specify it when this function called inside mixin `for-all-devices`
///                         Otherwise default will be `xl`
/// @return {Mixed}       - the value

$current-grid-size: 'xl' !global;
@function grid-var($key, $size: $current-grid-size) {
    @return map-get(map-get($map: $grid, $key: $size), $key);
}

/// Create grid item's size value
/// @param {String} $size - device size
/// @param {Number} $cols - item size in columns
/// @return {Mixed}       - the value

@function grid-item-width($size, $cols, $widthLimit: 100vw) {
    $totalCols: grid-var('columns', $size);
    $padding: grid-var('padding', $size);
    $gap: grid-var('gap', $size);

    $space: $padding * 2;
    $allGaps: $gap * ($totalCols - 1);
    $gaps: $gap * ($cols - 1);

    @return calc(#{$cols} * ((#{$widthLimit} - #{$space} - #{$allGaps}) / #{$totalCols}) + #{$gaps});
}

/// Takes css @content and duplicates it for all devices using `grid-var` for every device

@mixin for-all-devices() {
    @each $size, $vars in $grid {
        @if $size == 'xl' {
            $current-grid-size: $size !global;
            @content;
            $current-grid-size: 'xl' !global;
        } @else {
            @include media-breakpoint-only($size) {
                $current-grid-size: $size !global;
                @content;
                $current-grid-size: 'xl' !global;
            }
        }
    }
}
