@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/breakpoints';

.uploadFile {
    width: fit-content;
    height: 64px;

    @include media-breakpoint-only(xs) {
        height: 58px;
    }

    @include themed() {
        background-color: themed-color('input');
    }
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.preview {
    width: 60px;
    height: 60px;
    position: relative;

    @include media-breakpoint-only(xs) {
        width: 54px;
        height: 54px;
    }
}
.image {
    display: block;
    object-fit: cover;
    width: 100%;
}
.content {
    padding: 6px 10px;
}
.name {
    line-height: 2.4rem;
}
.size {
    text-transform: uppercase;
    line-height: 2.4rem;
}
.cancelBtn {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: 0 22px;
    outline: none;
}
.progressWrap {
    height: 4px;
    width: 100%;
    @include themed() {
        background-color: themed-color('border');
    }
}
.progress {
    height: 4px;
    @include themed() {
        background-color: themed-color('success');
    }
}
