@import '../../../../../shared/styles/colors';
@import '../../../../../shared/styles/easings';
@import '../../../../../shared/styles/mixins';

.searchWrap {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    height: 100%;

    animation: searchAppearing $durNorm $easeOutBack;
    animation-fill-mode: both;
    animation-play-state: running;
}

@keyframes searchAppearing {
    from {
        opacity: 0;
        transform: translateX(40px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.searchIconWrap {
    display: flex;
    align-items: center;
    position: absolute;
    left: 4rem;
    top: 0;
    height: 100%;
    pointer-events: none;
}

.searchInput {
    flex: 1 1 auto;
    display: block;
    padding: 0 17rem 0 8rem;
    height: 100%;
    border: none;
    background: none;
    box-sizing: border-box;

    @include text-size-regular();

    &:focus {
        outline: none;
    }

    @include themed() {
        color: themed-color('text');

        &::placeholder {
            color: themed-color('label');
        }

        &:-ms-input-placeholder {
            color: themed-color('label');
        }

        &::-ms-input-placeholder {
            color: themed-color('label');
        }
    }
}

.searchHintWrap,
.searchCloseWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
}

.searchCloseWrap {
    right: 0;
}

.searchHintWrap {
    right: 5rem;
    transition: opacity ease $durFast, transform $easeOutBack $durMed;

    &.isHidden {
        transform: scale(0.9);
        opacity: 0;
    }
}

.searchCloseButton {
    @include button();
    pointer-events: initial;
}

.searchHint {
    @include button();
    display: flex;
    align-items: center;
    padding: 0.5rem;
    pointer-events: initial;
}

.searchHintIcon {
    margin-left: 1rem;
}
