@import '../../../shared/styles/colors';

.separator {
    margin: 0;
    padding: 0;
    border: 0;
    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }
}
