@import '../../../../../../../shared/styles/colors';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    @include themed() {
        background-color: themed-color('input');
    }
}
