@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';

$itemIndent: 30px;
$separatorWidth: 8px;

.breadcrumbs {
    max-width: 100%;
    overflow: hidden;

    .items {
        display: inline-flex;
        align-items: center;
        list-style: none;
        max-width: 100%;
    }

    .item {
        flex: 0 0 auto;
        position: relative;
        padding-right: $itemIndent;
        text-decoration: none;

        &:last-of-type {
            padding-right: 0;
        }

        &.separator {
            &:after {
                position: absolute;
                content: '';
                right: (($itemIndent - $separatorWidth) / 2);
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                @include themed() {
                    border-left: $separatorWidth solid themed-color('icon');
                }
            }
        }

        p {
            @include text-size-small();
        }

        &:last-child {
            flex: 0 1 auto;
            overflow: hidden;
            white-space: nowrap;

            & > * {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
