@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/breakpoints';

.option {
    height: 6rem;
    width: 100%;
    padding: 0 2.5rem 0 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: background-color ease $durNorm;

    @include suisse-intl();
    font-size: 1.8rem;
    line-height: 6rem;
    text-align: left;

    @include themed() {
        color: themed-color('text');
    }

    @include media-breakpoint-only(xs) {
        height: 5rem;
        font-size: 1.6rem;
        line-height: 5rem;
        padding: 0 2rem 0 1.5rem;
    }

    &:hover {
        transition-duration: $durFast;
        @include themed() {
            background: themed-color('border');
        }
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;

        @include themed() {
            color: themed-color('label');
        }

        &:hover {
            background: transparent;
        }
    }

    &.selected {
        @include themed() {
            color: themed-color('accent');
        }

        &:hover {
            background: transparent;
        }
    }
}
