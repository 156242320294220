@import '../../shared/styles/breakpoints';
@import '../../shared/styles/colors';

.Notification {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 1rem;

    @include media-breakpoint-down(xs) {
        padding: 0.5rem 1rem;
    }

    @include themed() {
        color: themed-color('text');

        &_type_info {
            background: themed-color('notification-info');
        }
        &_type_warning {
            background: themed-color('notification-warning');
        }
        &_type_success {
            background: themed-color('notification-success');
        }
        &_type_error {
            background: themed-color('notification-error');
        }
    }
}

.icon {
    flex: none;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-top: 0.2rem;

    @include media-breakpoint-down(xs) {
        margin-top: 0.1rem;
    }
}

.content {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 1rem;
    line-height: 2rem;
}

.close {
    cursor: pointer;
    box-sizing: border-box;
    flex: none;
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
    margin-top: 0.2rem;
}
