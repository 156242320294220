@import '../../shared/styles/colors';

.ContentTable {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;

    @include themed() {
        border-top: 2px solid themed-color('border');
    }

    tr {
        @include themed() {
            border-bottom: 2px solid themed-color('border');
        }
    }

    td:first-of-type > div {
        padding-left: 0 !important;
    }
}
