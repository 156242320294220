@import '../../../../../shared/styles/colors';
@import '../../../../../shared/styles/breakpoints';
@import '../../../../../shared/styles/mixins';

.wrapper {
    position: relative;
    width: 2rem;
}

.counter {
    position: absolute;
    bottom: 50%;
    left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    @include themed() {
        background-color: themed-color('fail');
    }
    @include media-breakpoint-up(sm) {
        min-width: 2rem;
        height: 2rem;
    }
}

.button {
    @include button();
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
}
