@import '../../../shared/styles/colors';
@import '../../../shared/styles/breakpoints';

.radioTab {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    height: 6rem;
    padding: 2px 2rem 0 0;

    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }

    @include media-breakpoint-only(xs) {
        height: 5rem;
        min-width: unset;
    }

    &:not(.wide) {
        width: fit-content;
    }

    &.size--small {
        height: 4rem;
    }

    &.checked {
        @include themed() {
            border-color: themed-color('accent');
        }
    }

    &.hasError {
        @include themed() {
            border-color: themed-color('fail');
        }
    }
}

.nativeRadio {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
}
