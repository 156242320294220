.radioButtonGroup {
    display: flex;

    &.wide {
        width: 100%;
        & > label {
            flex-grow: 1;
        }
    }

    &.isCompact {
        label {
            margin-left: 0;
            margin-right: 0;

            div {
                border-right-width: 1px !important;
                border-left-width: 1px !important;
            }

            &:first-of-type > div {
                border-left-width: 2px !important;
                border-right-width: 1px !important;
            }

            &:last-of-type > div {
                border-right-width: 2px !important;
                border-left-width: 1px !important;
            }
        }
    }
}
