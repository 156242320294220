@import '../../../shared/styles/colors';

.linkBack {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    position: relative;
    display: block;
    padding-left: 20px;
    border: none;
    background: none;

    &:hover {
        text-decoration: underline;
    }

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        @include themed() {
            border-right: 8px solid themed-color('accent');
        }
    }
}
