@import '../../shared/styles/breakpoints';

.textIndent {
    text-indent: 6rem;

    @include media-breakpoint-down(sm) {
        text-indent: 4rem;
    }

    @include media-breakpoint-down(xs) {
        text-indent: 3rem;
    }
}
