@import '../../../../../../shared/styles/breakpoints';
@import '../../../../../../shared/styles/colors';
@import '../../../../../../shared/styles/mixins';

$headerDesktopHeight: 80px;

.headerLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4rem;
    height: $headerDesktopHeight;
    white-space: nowrap;
    text-decoration: none;
    @media (max-width: 1280px) {
        margin-left: 2rem;
    }
    @include media-breakpoint-down(xl) {
        margin-left: 3rem;
    }
}

.headerLinkActive {
    @extend .headerLink;

    position: relative;

    @include themed() {
        color: themed-color('accent');
    }

    &:after {
        content: '';
        position: absolute;
        left: -10%;
        bottom: 0;
        width: 120%;
        height: 100%;

        @include themed() {
            border-bottom: 2px solid themed-color('accent');
        }
    }
}
