@font-face {
    font-family: 'Halvar Engschrift';
    src: url('../../fonts/Halvar-Engschrift-ExtraBold.woff2') format('woff2'),
        url('../../fonts/Halvar-Engschrift-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Halvar Engschrift';
    src: url('../../fonts/Halvar-Engschrift-Regular.woff2') format('woff2'),
        url('../../fonts/Halvar-Engschrift-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('../../fonts/SuisseIntl-Bold.woff2') format('woff2'),
        url('../../fonts/SuisseIntl-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('../../fonts/SuisseIntl-SemiBold.woff2') format('woff2'),
        url('../../fonts/SuisseIntl-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('../../fonts/SuisseIntl-Medium.woff2') format('woff2'),
        url('../../fonts/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('../../fonts/SuisseIntl-Regular.woff2') format('woff2'),
        url('../../fonts/SuisseIntl-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
