.PopoverMenu {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    &_iconWrapper {
        display: inline-block;
        margin-left: -1rem;
        margin-right: -1rem;

        &_icon {
            cursor: pointer;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}
