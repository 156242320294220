@import '../../../shared/styles/breakpoints';
@import '../../../shared/styles/colors';

.blockLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 30px;
    padding: 0 10px;
    cursor: default;
    @include media-breakpoint-down(sm) {
        height: 24px;
        padding: 0 5px;
    }

    // types
    @include themed() {
        color: themed-color('text-inverted');
        background-color: themed-color('text');

        &.type-accent {
            background-color: themed-color('accent');
        }
        &.type-success {
            background-color: themed-color('success');
        }
        &.type-warning {
            background-color: themed-color('warning');
        }
        &.type-fail {
            background-color: themed-color('fail');
        }
    }
}
