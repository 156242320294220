@import '../../../shared/styles/colors';

.list {
    li {
        list-style: none;
        position: relative;
    }

    a {
        line-height: unset;
    }

    &.ordered {
        padding-left: 1.6rem;

        li {
            list-style-type: decimal;
        }
    }

    &.unordered {
        li {
            padding-left: 1.6rem;
        }

        li:before {
            content: '';
            position: absolute;
            left: 0;
            top: 1.5rem;
            transform: translateY(-50%);
            width: 0.6rem;
            height: 0.6rem;
            @include themed() {
                background-color: themed-color('text');
            }
        }
    }
}
