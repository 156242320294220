@import '../../../shared/styles/colors';

.Attachment {
    width: 100%;
    max-width: 35rem;
    height: 6rem;
    display: flex;
    flex-direction: row;
    position: relative;

    @include themed() {
        background-color: themed-color('input');
    }
}
.iconWrap {
    width: 6rem;
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include themed() {
        background-color: themed-color('border');
    }

    img {
        width: 6rem;
        height: 6rem;
        object-fit: cover;
    }
}
.content {
    box-sizing: border-box;
    max-width: calc(100% - 6rem);
    padding: 0 4rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2.4rem;
    padding-right: 3rem;
}
.link {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2.4rem;
}
.full {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.typeIcon {
    position: absolute;
    padding: 1rem 1.5rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
