@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/breakpoints';

.select {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.isOpened {
        .caret {
            transform: rotate(180deg);
        }
    }
}

.trigger {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6rem;
    padding: 0 2.5rem 0 2rem;
    background: transparent;
    text-align: left;
    cursor: pointer;
    outline: none;

    @include suisse-intl();
    @include text-color-text();
    font-size: 1.8rem;
    line-height: 6rem;

    @include themed() {
        border: 2px solid themed-color('border');
    }

    @include media-breakpoint-only(xs) {
        height: 5rem;
        padding: 0 2rem 0 1.5rem;
        font-size: 1.6rem;
        line-height: 5rem;
    }

    &:disabled {
        cursor: default;

        @include themed() {
            color: themed-color('label');

            .caret {
                border-top: 9px solid themed-color('label');
            }
        }
    }

    &.hasError {
        @include themed() {
            border: 2px solid themed-color('fail');
        }
    }

    &.isOpened:not(.hasError) {
        border-bottom: 0;
        padding-bottom: 2px;
    }
}

.triggerText {
    max-width: calc(100% - 3rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &_placeholder {
        @include themed() {
            color: themed-color('label');
        }
    }
}

.caret {
    @include themed() {
        border-top: 7px solid themed-color('text');
    }
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: transform $durNorm $easeOutBack;

    &.native {
        position: absolute;
        right: 2.5rem;

        @include media-breakpoint-only(xs) {
            right: 2rem;
        }
    }
}

.nativeSelect {
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0;
    width: 100%;
    height: 6rem;
    padding: 0 6rem 0 2rem;
    background: transparent;
    text-align: left;
    font-size: 1.8rem;
    line-height: 6rem;
    cursor: pointer;

    @include themed() {
        color: themed-color('text');
        border: 2px solid themed-color('border');
    }

    @include media-breakpoint-only(xs) {
        height: 5rem;
        line-height: 5rem;
        font-size: 1.6rem;
        padding: 0 4.8rem 0 2rem;
    }

    ~ .caret {
        pointer-events: none;
        transform: rotate(0deg);
    }

    &:active ~ .caret {
        transform: rotate(180deg);
    }

    &:disabled {
        cursor: default;

        @include themed() {
            color: themed-color('label');
            ~ .caret {
                border-top: 9px solid themed-color('label');
            }
        }
    }

    &.hasError {
        @include themed() {
            border: 2px solid themed-color('fail');
        }
    }
}
