@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/breakpoints';

.radioButton {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    height: 6rem;
    padding: 0 2rem;

    @include themed() {
        border: 2px solid themed-color('border');
    }

    @include media-breakpoint-only(xs) {
        height: 5rem;
        padding: 0 1.5rem;
    }

    &:not(.wide) {
        width: fit-content;
    }

    &.size--small {
        height: 4rem;
        padding: 0 1.5rem;

        span {
            font-size: 1.2rem;
        }
    }

    &.hasError {
        @include themed() {
            border: 2px solid themed-color('fail');
        }
    }
}
.nativeRadio {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
}

.checked {
    border-width: 2px;
    @include themed() {
        border-color: themed-color('accent');
    }
}
