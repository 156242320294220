@import '../../shared/styles/colors';

.ContentWarning {
    width: 100%;
    box-sizing: border-box;

    @include themed() {
        background-color: themed-color('input');
        border-left: 4px solid themed-color('accent');
    }
}
