@import '../../shared/styles/colors';

.ContentYoutube {
    position: relative;
    padding-top: 56.25%; // percentage ratio for 16:9
    @include themed() {
        background: themed-color('skeleton');
    }

    > div {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.ContentYoutubeLazyLoadPlaceholder {
    height: 0;
    padding-top: 56.25%; // percentage ratio for 16:9
    @include themed() {
        background: themed-color('skeleton');
    }
}
