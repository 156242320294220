@import '../../../../shared/styles/colors';
@import '../../../../shared/styles/breakpoints';
@import '../../../../shared/styles/mixins';

.cropperWrap {
    width: 100%;
    margin: 4rem auto;
    max-height: 50rem;
    @include media-breakpoint-down(lg) {
        margin-top: 2rem;
        max-height: 35rem;
    }
    @include media-breakpoint-down(sm) {
        position: absolute;
        top: calc(50% - 5rem);
        left: 0;
        transform: translateY(-50%);
    }
}

.modalWrap {
    overflow: auto;
}

.cropperModal {
    :global {
        .cropper-modal {
            background-color: transparent;
            opacity: 0;
        }

        .cropper-wrap-box {
            overflow: visible;
            opacity: 0.5;
        }

        .cropper-view-box {
            @include themed() {
                outline: 2px solid themed-color('text-inverted');
            }
        }

        .cropper-line {
            @include themed() {
                background-color: themed-color('background');
            }
        }
    }
}

.button {
    position: relative;
    z-index: 100;
}

.actions {
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-down(sm) {
        position: absolute;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 4rem;
        width: unset;
    }

    > div {
        width: calc(50% - 0.5rem);
        max-width: 20rem;
        min-width: 15rem;
        margin: 0 0.5rem;
    }
}

.header {
    display: none;
    @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 5rem;
        z-index: 10;

        @include themed() {
            background-color: themed-color('text-inverted');
        }
    }
}

.closeIcon {
    display: none;
    @include media-breakpoint-down(sm) {
        display: block;
        margin-right: 2rem;
        z-index: 100;
    }
}

.emptyGridItem {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
