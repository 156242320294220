.scrollContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.scrollContainerInner {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: scroll;
    padding: 0 20px 20px 0;
    margin: 0 -20px -20px 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
