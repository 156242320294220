@import '../../../shared/styles/colors';

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.9rem;
    width: fit-content;
    min-width: 1.9rem;
    margin-left: 8px;
    padding: 0 2px;
    box-sizing: border-box;
    @include themed() {
        background: themed-color('label');
    }
}

.isActive {
    @include themed() {
        background: themed-color('text');
    }
}
