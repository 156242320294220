@import '../../../../../../shared/styles/mixins';
@import '../../../../../../shared/styles/colors';

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    @include button();
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    @include themed() {
        background: themed-color('text');
    }
}

.avatar {
    margin-bottom: 1rem;
}

.usernameWrap {
    display: flex;
    align-items: center;
}
