.inputGroup {
    display: flex;

    input {
        flex: 1 1 auto;
    }

    button {
        flex: 0 0 auto;
    }
}
