@import '../../../shared/styles/colors';

.socials {
    display: flex;
    flex-direction: row;
}
.socialLink {
    margin-right: 0.5rem;
}

.icon {
    @include themed() {
        background-color: themed-color('text');
    }
}
