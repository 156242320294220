@import '../../shared/styles/colors';

.ContentSoundcloud {
    @include themed() {
        background: themed-color('skeleton');
    }
}

.ContentSoundcloudLazyLoadPlaceholder {
    height: 166px;
    @include themed() {
        background: themed-color('skeleton');
    }
}
