@import '../../shared/styles/colors';
@import '../../shared/styles/typography';
@import '../../shared/styles/breakpoints';

.ArticleBodyQuote {
    position: relative;
    padding: 3rem 3rem 3rem 6rem;
    @include themed() {
        background: themed-color('input');
    }

    span {
        word-break: break-all;
        a {
            @include text-color-accent();
        }
    }

    @include media-breakpoint-down(xs) {
        padding: 2rem 1.5rem 2rem 3rem;
    }

    &_source {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;

        @include text-size-small();
        @include text-color-label();

        span {
            word-break: break-all;
            a {
                @include text-color-accent();
            }
        }
    }

    &_text {
        & + & {
            margin-top: 2rem;
        }
    }
}

.quote {
    position: absolute;
    top: 2.2rem;
    left: 0;
    @include media-breakpoint-up(sm) {
        top: 3.6rem;
    }
}
