@import '../../../shared/styles/colors';

.RadioDot {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nativeRadio {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
}

.icon {
    margin-right: 1rem;
    @include themed() {
        stroke: themed-color('icon');
        fill: transparent;
    }
}

.iconActive {
    @include themed() {
        stroke: themed-color('accent');
        fill: themed-color('accent');
    }
}
