@import '~reset-css/sass/_reset.scss';
@import './fonts';

html {
    font-size: 62.5%; // To easy calculate rem units - 14px = 1.4rem
}

body {
    font-family: 'Suisse Intl', 'Helvetica Neue', 'Open Sans', sans-serif;
    font-size: 1.8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.htmlScrollLock {
    overflow-y: auto;
}

.bodyScrollLock {
    overflow: hidden;
    position: fixed;
    width: 100%;
}
