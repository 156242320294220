@import '../../../shared/styles/colors';

.icon {
    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    backface-visibility: hidden;

    path {
        fill: none;
        stroke: currentColor;
    }

    &:not(:root) {
        overflow: hidden;
    }

    // sizes
    &.size-small {
        width: 1.5rem;
        height: 1.5rem;

        path {
            stroke-width: 1.5;
        }
    }

    &.size-medium {
        width: 2rem;
        height: 2rem;

        path {
            stroke-width: 2;
        }
    }

    &.size-large {
        width: 2.5rem;
        height: 2.5rem;

        path {
            stroke-width: 2.5;
        }
    }

    &.size-extra-large {
        width: 3rem;
        height: 3rem;

        path {
            stroke-width: 3;
        }
    }

    &.size-largest {
        width: 6rem;
        height: 6rem;

        path {
            stroke-width: 6;
        }
    }

    &.size-inherit {
        width: 100%;
        height: 100%;

        path {
            stroke-width: 100%;
        }
    }

    // colors
    @include themed() {
        &.color-text {
            color: themed-color('text');
        }
        &.color-text-inverted {
            color: themed-color('text-inverted');
        }
        &.color-label {
            color: themed-color('label');
        }
        &.color-icon {
            color: themed-color('icon');
        }
        &.color-border {
            color: themed-color('border');
        }
        &.color-input {
            color: themed-color('input');
        }
        &.color-background {
            color: themed-color('background');
        }
        &.color-accent {
            color: themed-color('accent');
        }
        &.color-success {
            color: themed-color('success');
        }
        &.color-warning {
            color: themed-color('warning');
        }
        &.color-fail {
            color: themed-color('fail');
        }
    }

    &.inherit {
        color: inherit;
    }
}
