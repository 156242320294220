@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/breakpoints';

@mixin textStyles {
    @include text-size-regular();
    font-weight: normal;
}

.inputWrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

@mixin labelActive {
    transform: translateY(-0.8rem) scale(0.75);
    @include media-breakpoint-up(sm) {
        transform: translateY(-1rem) scale(0.8);
    }
}

.label {
    @include textStyles();
    position: absolute;
    top: 1.3rem;
    left: 1.5rem;
    pointer-events: none;
    will-change: transform;
    transform-origin: top left;
    transition: transform $easeOutCubic $durNorm;

    @include themed() {
        color: themed-color('label');
    }

    @include media-breakpoint-up(sm) {
        top: 1.5rem;
        left: 2rem;
    }

    &.isScaled {
        @include labelActive();
    }
}

.input {
    @include textStyles();
    display: block;
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2.1rem 1.5rem 0.5rem;
    resize: none;
    -webkit-appearance: none;
    border-radius: 0;

    @include themed() {
        background-color: themed-color('input');
    }

    @include media-breakpoint-up(sm) {
        padding: 2.4rem 2.1rem 0.6rem 2rem;
    }

    &.withIcon {
        padding-right: 5rem;
        @include media-breakpoint-up(sm) {
            padding-right: 6rem;
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.isCompact {
        padding: 1.3rem 5rem;
        @include media-breakpoint-up(sm) {
            padding: 0.8rem 4rem 0.8rem 5rem;
            @include text-size-small();
        }
    }
    &:-webkit-autofill + .label {
        @include labelActive();
    }
}

.textarea {
    width: calc(100% + 2rem);
    margin-right: -2rem;

    padding: 2.1rem 3.5rem 0.5rem 1.5rem !important;

    @include media-breakpoint-up(sm) {
        padding: 2.4rem 2.1rem 0.6rem 2rem !important;
    }

    &_isCompact {
        padding: 1.3rem 7rem 1.3rem 5rem !important;
        @include media-breakpoint-up(sm) {
            padding: 0.8rem 6rem 0.8rem 5rem !important;
        }
    }

    &_withIcon {
        padding-right: 7rem !important;
        @include media-breakpoint-up(sm) {
            padding-right: 9rem !important;
        }
    }
}

.iconWrap {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-only(xs) {
        right: 1.5rem;
    }
}

.iconBtn {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    @include media-breakpoint-only(xs) {
        right: 1.5rem;
    }
}

.inputWrap.showFocusLine {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.1rem;
        transform: scale(0);
        transform-origin: left center;
        transition: transform $easeOutCubic $durNorm;

        @include themed() {
            background: themed-color('label');
        }
    }
    &.isFocused:after {
        transform: scale(1);
    }
    &.hasError:after {
        transform: scale(1);
        @include themed() {
            background: themed-color('fail');
        }
    }
}
