@import '../../../shared/styles/typography';
@import '../../../shared/styles/breakpoints';
@import '../../../shared/styles/colors';

.menu {
    max-width: 35rem;

    @include media-breakpoint-down(sm) {
        max-width: unset;
    }
}

.item {
    padding: 2.5rem 0;
    cursor: pointer;

    @include text-size-regular();

    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }

    @include media-breakpoint-down(sm) {
        padding: 2rem 0;
        text-align: left;
    }

    &:first-child {
        @include media-breakpoint-up(lg) {
            @include themed() {
                border-top: 2px solid themed-color('border');
            }
        }
    }
}

.active {
    @include themed() {
        color: themed-color('accent');
    }
}

.menuItemsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuIcon {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
