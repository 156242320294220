@import '../../../../../../../shared/styles/colors';
@import '../../../../../../../shared/styles/mixins';

.profileInfoWrap {
    width: 340px;
}

.profileInfoHeader {
    display: flex;
    padding-bottom: 1.5rem;
    @include text-break();
    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }
}

.profileInfoLink {
    @include button();
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 1.5rem;
}

.usernameWrap {
    display: flex;
    align-items: center;
}
