@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/grid';
@import '../../../shared/styles/mixins';

.skeleton {
    width: 100%;
    box-sizing: border-box;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    animation: slideleft 1.5s linear infinite;
    background-position-x: -100%;

    @include themed() {
        background-color: themed-color('input');
        background-image: linear-gradient(90deg, themed-color('input'), themed-color('border'), themed-color('input'));
    }

    &.round {
        border-radius: 50%;
    }
}

@keyframes slideleft {
    from {
        background-position: -100% 0px;
    }
    to {
        background-position: 200% 0px;
    }
}
