@import '../../shared/styles/mixins';

.AuthorPreview {
    display: flex;

    &_info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include text-break();
    }

    &_name {
        display: flex;
        align-items: center;
        @include text-break();
        margin-top: -0.8rem;
    }

    &_information {
        @include text-break();
    }
}
