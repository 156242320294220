@import '../../../../shared/styles/mixins';
@import '../../../../shared/styles/_colors.scss';

.wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 5rem;
    @include themed() {
        background-color: themed-color('background');
    }
}

.button {
    @include button();
}

.arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}

.cancel {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
