@import '../../../shared/styles/colors';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/breakpoints';

.footer {
    @include themed() {
        background: themed-color('background');
    }

    @include media-breakpoint-down(xs) {
        @include themed() {
            border-top: 2px solid themed-color('border');
        }
    }
}

.footerContainer {
    padding-top: 4.5rem;
    padding-bottom: 4rem;

    @include media-breakpoint-up(sm) {
        padding-top: 5rem;

        @include themed() {
            border-top: 2px solid themed-color('border');
        }
    }

    @include media-breakpoint-only(xs) {
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
}

.navCol {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        margin-bottom: 2rem !important;
    }
}

.navItem {
    width: fit-content;
    margin-bottom: 1rem;
}

.rightSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        margin-top: 1rem !important;
        flex-direction: column-reverse;
    }
}

.mailBtn {
    @include styled-link();
    width: fit-content;
    line-height: 2.4rem !important;
}

.bottomRow {
    padding-top: 1.5rem;

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
    }
}

.socialsDesktopWrap {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.socialsMobileWrap {
    @include media-breakpoint-up(lg) {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
    }
}
