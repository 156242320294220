@import '../../shared/styles/breakpoints';

// 1rem === 10px
$baseline: 1rem;

$grid-width-limit: 1920px;

/**
 * Current grid size
 * Default: `xl`
 */
$current-grid-size: 'xl' !global;

/**
 * Grid breakpoints configuration
 */
$grid: (
    xl: (
        breakpoint: map_get($breakpoints, 'xl'),
        padding: 10rem,
        columns: 12,
        gap: 3rem,
    ),
    lg: (
        breakpoint: map_get($breakpoints, 'lg'),
        padding: 4rem,
        columns: 12,
        gap: 3rem,
    ),
    sm: (
        breakpoint: map_get($breakpoints, 'sm'),
        padding: 4rem,
        columns: 4,
        gap: 2rem,
    ),
    xs: (
        breakpoint: map_get($breakpoints, 'xs'),
        padding: 1.5rem,
        columns: 4,
        gap: 1.5rem,
    ),
);

/**
 * Get value from `$grid` config
 *
 * @param {String} $key  Key of requested value
 * @param {String} $size Breakpoint name
 *                       You don't need to specify it when this function called inside mixin `for-all-devices`
 *                       Default: `xl`
 * @return {Mixed}       Value
 */
@function grid-var($key, $size: $current-grid-size) {
    @return map-get(map-get($map: $grid, $key: $size), $key);
}

/**
 * Create grid col's size value
 *
 * @param {String} $size Breakpoint name
 * @param {Number} $cols Cols count
 * @return {Mixed}       Value
 */
@function grid-col-width($size, $cols, $widthLimit: 100vw) {
    $totalCols: grid-var('columns', $size);
    $padding: grid-var('padding', $size);
    $gap: grid-var('gap', $size);

    $space: $padding * 2;
    $allGaps: $gap * ($totalCols - 1);
    $gaps: $gap * ($cols - 1);

    @return calc(#{$cols} * ((#{$widthLimit} - #{$space} - #{$allGaps}) / #{$totalCols}) + #{$gaps});
}

/**
 * Takes css @content and duplicates it for all devices
 */
@mixin for-all-devices() {
    @each $size, $vars in $grid {
        @if $size == 'xl' {
            $current-grid-size: $size !global;
            @content;
            $current-grid-size: 'xl' !global;
        } @else {
            @include media-breakpoint-only($size) {
                $current-grid-size: $size !global;
                @content;
                $current-grid-size: 'xl' !global;
            }
        }
    }
}
