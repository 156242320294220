@import '../../shared/styles/colors';

.ContentImage {
    width: auto;
    height: auto;
    max-height: 70rem;
    max-width: 100%;
    @include themed() {
        background: themed-color('skeleton');
    }

    &_full {
        width: 100%;
    }

    &_lazy {
        position: absolute;
        transform: translateY(-100%);
    }
    &__lazyWrapper {
        position: relative;
    }
}
