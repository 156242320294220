@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/breakpoints';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/grid';

.head {
    position: relative;
}

.titles {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    height: 9rem;
    padding: 3rem;
    cursor: pointer;
    user-select: none;
    border: none;
    white-space: nowrap;
    @include heading-h3();
    @include text-color-label();
    @include themed() {
        background-color: themed-color('input');
        border-right: 2px solid themed-color('icon');
    }

    &:last-child {
        border-right: 0;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        @include themed() {
            background-color: themed-color('border');
        }
    }

    @include media-breakpoint-only(xs) {
        height: 6rem;
        padding: 1.8rem 1.5rem;
    }

    &.isActive {
        height: 10rem;
        padding-top: 4rem;
        margin-left: -2px;

        @include themed() {
            color: themed-color('text');
            background-color: themed-color('text-inverted');
            border-left: 2px solid themed-color('text-inverted');
            border-right: 2px solid themed-color('text-inverted');
        }

        @include media-breakpoint-only(xs) {
            height: 7rem;
            padding-top: 2.8rem;
        }
    }
}

.content {
    box-sizing: border-box;
    width: 100%;
    @include themed() {
        background: themed-color('text-inverted');
    }
    padding-top: 6rem;
    padding-bottom: 6rem;
    @include media-breakpoint-only(xs) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.isFullWidth {
    justify-content: space-between;

    @include for-all-devices() {
        margin-right: -(grid-var('gap'));
        margin-left: -(grid-var('gap'));
    }
    @include media-breakpoint-only(xs) {
        margin-right: -(grid-var('padding', 'xs'));
        margin-left: -(grid-var('padding', 'xs'));
    }

    .title {
        width: 100%;
    }
    .content {
        @include for-all-devices() {
            padding-right: (grid-var('gap'));
            padding-left: (grid-var('gap'));
        }
        @include media-breakpoint-only(xs) {
            padding-right: (grid-var('padding', 'xs'));
            padding-left: (grid-var('padding', 'xs'));
        }
    }
}
