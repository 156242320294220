@import '../grid';

.row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;

    @include for-all-devices() {
        margin-right: -(grid-var('gap') / 2);
        margin-left: -(grid-var('gap') / 2);
    }
}
