@import '../grid';

.col {
    flex: 0 0 auto;

    @include for-all-devices() {
        margin: 0 (grid-var('gap') / 2) 0 (grid-var('gap') / 2);
        @for $cols from 1 through grid-var('columns') {
            &_#{$current-grid-size}--#{$cols} {
                width: grid-col-width($current-grid-size, $cols);
                @media all and (min-width: $grid-width-limit) {
                    width: grid-col-width($current-grid-size, $cols, $grid-width-limit);
                }
            }
        }
    }
}
