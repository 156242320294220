@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/breakpoints';

.modalWrap,
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
}

.modalWrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
}

.overlay {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(14px);
    opacity: 1;
}

.body {
    margin: auto;
    padding: 40px 20px;
    width: 100%;
    flex: 0 1 auto;
    box-sizing: border-box;
    z-index: 100;
    @include media-breakpoint-down(sm) {
        height: 100%;
        padding: 0;
    }

    &.withScroll {
        flex: 0 0 auto;
    }
}

.contentWrap {
    width: fit-content;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
    @include media-breakpoint-down(sm) {
        // TODO need remove set style from js
        height: 100% !important;
        width: 100% !important;
    }
}

.content {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow: auto;
    user-select: text;
    @include media-breakpoint-down(sm) {
        min-height: 100%;
    }

    @include themed() {
        background-color: themed-color('background');
    }

    &.withScroll {
        max-height: initial;
    }

    &.isPadded {
        padding: 35px 40px;
    }
}

/* Animation */
.modalWrap {
    &.enter {
        & .overlay {
            opacity: 0;
            backdrop-filter: blur(0);
        }
        & .contentWrap {
            opacity: 0;
            transform: scale(0.8, 0.8);
        }
    }

    &.enterActive {
        & .overlay {
            opacity: 0.9;
            backdrop-filter: blur(14px);
            transition: opacity $easeOutBack 250ms;
        }
        & .contentWrap {
            opacity: 1;
            transform: scale(1);
            transition: opacity $easeOutBack 250ms, transform $easeOutBack 250ms;
        }
    }

    &.exitActive {
        & .overlay {
            opacity: 0;
            backdrop-filter: blur(0);
            transition: opacity $easeOutBack 250ms;
        }
        & .contentWrap {
            opacity: 0;
            transition: opacity $easeOutBack 250ms, transform $easeOutBack 250ms;
            transform: scale(0.9, 0.9);
        }
    }
}

.closeButton {
    position: absolute;
    top: 0;
    left: calc(100% + 2rem);
    z-index: 101;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
