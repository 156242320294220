@import '../../../shared/styles/typography';

.heading {
    &--h1 {
        @include heading-h1();
    }

    &--h2 {
        @include heading-h2();
    }

    &--h3 {
        @include heading-h3();
    }

    &--inline {
        display: inline;
    }

    &--wrap {
        @include text-break();
    }

    &-color {
        &--text-inverted {
            @include text-color-text-inverted();
        }

        &--label {
            @include text-color-label();
        }

        &--accent {
            @include text-color-accent();
        }

        &--fail {
            @include text-color-fail();
        }

        &--icon {
            @include text-color-icon();
        }

        &--inherit {
            @include text-color-inherit();
        }
    }
}
