@import 'breakpoints';

// If you're updating this, you need to update the units in the `Container` component as well.
$space-units: 0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 20;
$sides: left, right, top, bottom;

@mixin generate-spacing-classes-for($spacing-type, $spacing-side, $responsive-size) {
    @each $spacing in $space-units {
        $spacing-label: $spacing;
        $responsive-label: '';
        $property: $spacing-type;

        // Convert fractional numbers to dashed representation, ie 1.5 -> 1-5
        @if floor($spacing) != $spacing {
            $spacing-label: #{floor($spacing)}#{'-'}#{($spacing - floor($spacing)) * 10};
        }

        // Build css property, ie: `margin-right`
        $property: #{$spacing-type}#{'-'}#{$spacing-side};

        // Add responsive label if specified
        @if ($responsive-size != '') {
            $responsive-label: #{$responsive-size}#{'-'};
        }

        .#{$spacing-type}-#{$responsive-label}#{$spacing-side}-#{$spacing-label} {
            #{$property}: #{$spacing}rem !important;
        }
    }
}

@each $side in $sides {
    @include generate-spacing-classes-for('margin', $side, '');
}

@include media-breakpoint-down(sm) {
    @each $side in $sides {
        @include generate-spacing-classes-for('margin', $side, 'sm');
    }
}

@include media-breakpoint-down(xs) {
    @each $side in $sides {
        @include generate-spacing-classes-for('margin', $side, 'xs');
    }
}

@each $side in $sides {
    @include generate-spacing-classes-for('padding', $side, '');
}

@include media-breakpoint-down(sm) {
    @each $side in $sides {
        @include generate-spacing-classes-for('padding', $side, 'sm');
    }
}

@include media-breakpoint-down(xs) {
    @each $side in $sides {
        @include generate-spacing-classes-for('padding', $side, 'xs');
    }
}
