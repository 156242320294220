@import '../../../shared/styles/mixins';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/breakpoints';

.ToogleSwitch {
    @include button();
    display: flex;
    align-items: center;
}

.switch {
    position: relative;
    margin-right: 1.5rem;
    @include media-breakpoint-down(sm) {
        margin-right: 1rem;
    }
    width: 4rem;
    height: 2rem;
    @include themed() {
        background-color: themed-color('icon');
    }
    transition: background-color 0.3s ease;
    &::after {
        content: '';
        position: absolute;
        left: 0.2rem;
        top: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
        @include themed() {
            background-color: themed-color('text-inverted');
        }
        transition: left 0.3s ease;
    }
}

.isOn {
    @include themed() {
        background-color: themed-color('accent');
    }
    &::after {
        left: calc(100% - 1.8rem);
    }
}
