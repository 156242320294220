@import '../../../shared/styles/colors';

.Copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 3rem;
    box-sizing: border-box;

    &__text {
        @include themed() {
            border: 1px solid themed-color('text');
        }
        padding: 0 1rem;
        line-height: 2.8rem;
    }

    &__image {
        width: 3rem;
        height: 3rem;
    }
}
