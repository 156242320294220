@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/breakpoints';

.button {
    @include focus-outline-unfocused();

    &:focus-visible,
    &.focus {
        @include focus-outline-focused();
    }

    text-decoration: none;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    user-select: none;
    transition: outline-color ease $durMed, background-color ease $durMed, color ease $durMed;
    white-space: nowrap;

    @include suisse-intl();
    font-weight: normal;
    line-height: 100%;

    &.loadingWithText {
        position: relative;
        & .icon {
            position: absolute;
        }
        & .content {
            opacity: 0;
        }
    }

    &:hover,
    &.hover {
        transition-duration: $durFast;
    }

    &.normal {
        height: 4rem;
        padding: 0 1.5rem;
        @include text-size-small();
    }

    &.large {
        height: 6rem;
        padding: 0 3rem;
        @include text-size-regular();
        @include media-breakpoint-only(xs) {
            height: 5rem;
        }
    }

    &.square {
        width: 4rem;
        height: 4rem;
        padding: 0;

        &.large {
            height: 6rem;
            width: 6rem;

            @include media-breakpoint-only(xs) {
                width: 4rem;
                height: 4rem;
            }
        }

        .icon {
            margin: 0;
        }
    }

    &.primary {
        @include themed() {
            color: themed-color('text-inverted');
            border: 1px solid themed-color('text');
            background-color: themed-color('text');
        }

        &:hover,
        &.hover {
            @include themed() {
                background-color: themed-color('accent');
                border-color: themed-color('accent');
            }

            @include theme('dark') {
                @include themed() {
                    color: themed-color('text', 'dark');
                    background-color: themed-color('accent', 'light');
                    border-color: themed-color('accent', 'light');
                }
            }
        }

        @include media-breakpoint-down(sm) {
            &:hover,
            &.hover {
                @include themed() {
                    border: 1px solid themed-color('text');
                    background-color: themed-color('text');
                }

                @include theme('dark') {
                    @include themed() {
                        color: themed-color('text-inverted', 'dark');
                    }
                }
            }
        }

        &:active,
        &.pressed {
            @include themed() {
                background-color: themed-color('active');
                border-color: themed-color('active');
            }
        }

        &.active {
            @include themed() {
                color: themed-color('text-inverted', 'light');
                background-color: themed-color('accent', 'light');
                border-color: themed-color('accent', 'light');
            }
        }

        &.disabled,
        &[disabled] {
            @include themed() {
                color: themed-color('label');
                border-color: themed-color('border');
                background-color: themed-color('border');
            }

            @include theme('dark') {
                @include themed() {
                    color: themed-color('border', 'light');
                    background-color: themed-color('label', 'light');
                    border-color: themed-color('label', 'light');
                }
            }
        }
    }

    &.secondary {
        @include themed() {
            color: themed-color('text');
            border: 1px solid themed-color('text');
            background-color: transparent;
        }

        &:hover,
        &.hover {
            @include themed() {
                color: themed-color('accent', 'light');
                border-color: themed-color('accent', 'light');
            }
        }

        @include media-breakpoint-down(sm) {
            &:hover,
            &.hover {
                @include themed() {
                    color: themed-color('text');
                    border: 1px solid themed-color('text');
                }
            }
        }

        &:active,
        &.pressed {
            @include themed() {
                color: themed-color('active');
                border-color: themed-color('active');
            }
        }

        &.active {
            @include themed() {
                color: themed-color('accent', 'light');
                border-color: themed-color('accent', 'light');
            }
        }

        &.disabled,
        &[disabled] {
            @include themed() {
                color: themed-color('label');
                border-color: themed-color('border');
            }

            @include theme('dark') {
                @include themed() {
                    color: themed-color('label', 'light');
                    border-color: themed-color('label', 'light');
                }
            }
        }
    }

    &.default {
        @include themed() {
            color: themed-color('text', 'light');
            border: 1px solid themed-color('input', 'light');
            background-color: themed-color('input', 'light');
        }

        &.square {
            padding: 0;
        }

        &:hover,
        &.hover {
            @include themed() {
                border: 1px solid themed-color('border', 'light');
                background-color: themed-color('border', 'light');
            }
        }

        @include media-breakpoint-down(sm) {
            &:hover,
            &.hover {
                @include themed() {
                    color: themed-color('text', 'light');
                    border: 1px solid themed-color('input', 'light');
                    background-color: themed-color('input', 'light');
                }
            }
        }

        &:active,
        &.pressed {
            @include themed() {
                border-color: themed-color('icon', 'light');
                background-color: themed-color('icon', 'light');
            }
        }

        &.disabled,
        &[disabled] {
            @include themed() {
                border-color: themed-color('input') !important;
                background-color: themed-color('input') !important;
            }
        }
    }

    &.text {
        height: auto;
        padding: 0;
        border: none;
        background: none;
        @include text-size-small();
        @include themed() {
            color: themed-color('accent', 'light');
        }
    }
}

.disabled,
.button[disabled] {
    outline: none;
    cursor: default;
}

.full {
    width: 100%;
    box-sizing: border-box;
}

.icon {
    width: 2rem;
    height: 2rem;
}
.icon + .content {
    margin-left: 1rem;
}
