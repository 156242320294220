@import '../../../shared/styles/colors';

.PageLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    height: 100%;

    @include themed() {
        background-color: themed-color('background');
    }
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
}

.content {
    margin-bottom: auto;
}
