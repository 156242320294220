@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/mixins';

.socialButton {
    @include button();
    display: inline-block;
    padding: 4px 10px;
    user-select: none;
    text-decoration: none;

    @include text-size-small();
    @include text-color-text-inverted();
    font-weight: 600;

    &.vk {
        background-color: social-color('vk');
    }

    &.facebook {
        background-color: social-color('facebook');
    }

    &.telegram {
        background-color: social-color('telegram');
    }

    &.twitter {
        background-color: social-color('twitter');
    }

    &.youtube {
        background-color: social-color('youtube');
    }

    &.instagram {
        background: linear-gradient(
            120deg,
            social-color('instagram-1'),
            social-color('instagram-2'),
            social-color('instagram-3')
        );
    }
}
