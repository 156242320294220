@import '../../shared/styles/typography';
@import '../../shared/styles/colors';
@import '../../shared/styles/breakpoints';

.ContentList {
    & li {
        position: relative;
        margin-left: 6rem;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            @include text-size-regular();
        }

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 4rem;
        }

        @include media-breakpoint-down(xs) {
            margin-left: 3rem;
        }

        p {
            margin-left: 3rem;
        }
    }

    &_ordered {
        counter-reset: orderedListCounter;

        & li {
            counter-increment: orderedListCounter;

            &::before {
                @include suisse-intl();
                content: counters(orderedListCounter, '.') '.';
            }
        }
    }

    &_unordered {
        & li {
            &::before {
                top: 12px;
                width: 6px;
                height: 6px;

                @include themed() {
                    background-color: themed-color('text');
                }

                @include media-breakpoint-down(xs) {
                    top: 10px;
                }
            }

            p {
                margin-left: 1.6rem;
            }
        }
    }
}
