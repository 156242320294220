@import '../../../shared/styles/colors';

$tooltip-prefix-cls: 'xyz-popover';

@mixin getArrowShadow($firstParam, $secondParam, $thirdParam, $theme) {
    @include themed() {
        filter: drop-shadow($firstParam $secondParam $thirdParam rgba(themed-color('text', $theme), 0.1));
    }
}

$tooltip-arrow-offset: 11px;
// Tooltip arrow width
$tooltip-arrow-width: 9px;
// Tooltip distance with trigger
$tooltip-distance: $tooltip-arrow-width + 2;

@mixin getArrow($color, $theme) {
    &.#{$tooltip-prefix-cls} {
        &-placement-top .#{$tooltip-prefix-cls}-arrow,
        &-placement-topLeft .#{$tooltip-prefix-cls}-arrow,
        &-placement-topRight .#{$tooltip-prefix-cls}-arrow {
            bottom: $tooltip-distance - $tooltip-arrow-width;
            margin-left: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
            border-top-color: $color;
            @include getArrowShadow(0, 3px, 3px, $theme);
        }

        &-placement-right .#{$tooltip-prefix-cls}-arrow,
        &-placement-rightTop .#{$tooltip-prefix-cls}-arrow,
        &-placement-rightBottom .#{$tooltip-prefix-cls}-arrow {
            left: $tooltip-distance - $tooltip-arrow-width;
            margin-top: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
            border-right-color: $color;
            @include getArrowShadow(-3px, 0, 3px, $theme);
        }

        &-placement-left .#{$tooltip-prefix-cls}-arrow,
        &-placement-leftTop .#{$tooltip-prefix-cls}-arrow,
        &-placement-leftBottom .#{$tooltip-prefix-cls}-arrow {
            right: $tooltip-distance - $tooltip-arrow-width;
            margin-top: -$tooltip-arrow-width;
            border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-left-color: $color;
            @include getArrowShadow(3px, 0, 3px, $theme);
        }

        &-placement-bottom .#{$tooltip-prefix-cls}-arrow,
        &-placement-bottomLeft .#{$tooltip-prefix-cls}-arrow,
        &-placement-bottomRight .#{$tooltip-prefix-cls}-arrow {
            top: $tooltip-distance - $tooltip-arrow-width;
            margin-left: -$tooltip-arrow-width;
            border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
            border-bottom-color: $color;
            @include getArrowShadow(0, -3px, 3px, $theme);
        }
    }
}

:global {
    // Base class
    .#{$tooltip-prefix-cls} {
        position: absolute;
        z-index: 201;
        display: block;
        visibility: visible;

        &-hidden {
            display: none;
        }

        &-placement-top,
        &-placement-topLeft,
        &-placement-topRight {
            padding: $tooltip-arrow-width 0 $tooltip-distance 0;
        }
        &-placement-right,
        &-placement-rightTop,
        &-placement-rightBottom {
            padding: 0 $tooltip-arrow-width 0 $tooltip-distance;
        }
        &-placement-bottom,
        &-placement-bottomLeft,
        &-placement-bottomRight {
            padding: $tooltip-distance 0 $tooltip-arrow-width 0;
        }
        &-placement-left,
        &-placement-leftTop,
        &-placement-leftBottom {
            padding: 0 $tooltip-distance 0 $tooltip-arrow-width;
        }

        &-placement-top &-arrow {
            left: 50%;
        }

        &-placement-topLeft &-arrow {
            left: $tooltip-arrow-offset;
        }

        &-placement-topRight &-arrow {
            right: $tooltip-arrow-offset;
        }

        &-placement-right &-arrow {
            top: 50%;
        }

        &-placement-rightTop &-arrow {
            top: $tooltip-arrow-offset;
            margin-top: 0;
        }

        &-placement-rightBottom &-arrow {
            bottom: $tooltip-arrow-offset;
        }

        &-placement-left &-arrow {
            top: 50%;
        }

        &-placement-leftTop &-arrow {
            top: $tooltip-arrow-offset;
            margin-top: 0;
        }

        &-placement-leftBottom &-arrow {
            bottom: $tooltip-arrow-offset;
        }

        &-placement-bottom &-arrow {
            left: 50%;
        }

        &-placement-bottomLeft &-arrow {
            left: $tooltip-arrow-offset;
        }

        &-placement-bottomRight &-arrow {
            right: $tooltip-arrow-offset;
        }
    }

    // Wrapper for the tooltip content
    .#{$tooltip-prefix-cls}-inner {
        @include themed() {
            box-shadow: 0px 0px 5px rgba(themed-color('text'), 0.2);
        }
        padding: 15px;

        min-height: 20px;
        max-width: 100%;
        max-height: calc(100vh - 200px);
        overflow: auto;

        text-align: left;
        text-decoration: none;
    }

    // Arrows
    .#{$tooltip-prefix-cls}-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
    }

    @mixin getThemed($backgroundColor, $theme) {
        @include themed() {
            @include getArrow(themed-color($backgroundColor, $theme), $theme);
            .#{$tooltip-prefix-cls}-inner {
                background-color: themed-color($backgroundColor, $theme);
            }
        }
    }

    .theme-dark.#{$tooltip-prefix-cls}-background {
        @include getThemed('background', 'dark');
    }

    .theme-dark.#{$tooltip-prefix-cls}-text-inverted {
        @include getThemed('text-inverted', 'dark');
    }

    .theme-light.#{$tooltip-prefix-cls}-background {
        @include getThemed('background', 'light');
    }

    .theme-light.#{$tooltip-prefix-cls}-text-inverted {
        @include getThemed('text-inverted', 'light');
    }
}
