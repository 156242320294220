@import '../../../../shared/styles/typography';
@import '../../../../shared/styles/colors';
@import '../../../../shared/styles/mixins';

$headerDesktopHeight: 8rem;

.header {
    width: 100%;
    @include themed() {
        background-color: themed-color('background');
    }
}

.headerContainer {
    display: flex;
    align-items: center;
    height: $headerDesktopHeight;
    box-sizing: border-box;

    @include themed() {
        box-shadow: inset 0px -2px 0px 0px themed-color('border');
    }
}

.logoWrap {
    @include button();

    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: $headerDesktopHeight;
    height: $headerDesktopHeight;

    @include themed() {
        background-color: themed-color('text');
    }
}

.siteName {
    margin-left: 2rem;
    font-style: normal;

    &.beta {
        position: relative;
        margin-right: 2rem;
        &:before {
            content: '';
            position: absolute;
            top: -0.2rem;
            right: -2rem;
            background: url('../../../../assets/beta.svg') center center / cover no-repeat;
            width: 3rem;
            height: 3rem;
            transform: rotate(-30deg);
        }
    }
}
