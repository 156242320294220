@import '../../../../../../shared/styles/colors';
@import '../../../../../../shared/styles/mixins';

.navLinkActive {
    @include button();

    width: 100%;
}

.navLinkActiveText {
    @include themed() {
        color: themed-color('accent');
    }
}
