@import '../../../../../shared/styles/mixins';
@import '../../../../../shared/styles/breakpoints';

.headerNav {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
}

.links {
    display: flex;
}

.controls {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.searchButton {
    @include button();
    cursor: pointer;
    padding: 0.5rem;
}

.controlItem {
    position: relative;
    padding: 0.5rem;
    margin-left: 3rem;
    @media (max-width: 1280px) {
        margin-left: 1rem;
    }
    @include media-breakpoint-down(xl) {
        margin-left: 2rem;
    }
}

.servicesWrapper {
    margin-right: -1rem;
}

.notificationsWrapper {
    margin-left: 1rem;
}
