@import 'breakpoints.scss';

@each $breakpoint in $breakpoint-names {
    @include media-breakpoint-down($breakpoint) {
        .layout-#{$breakpoint}-d-inline-block {
            display: inline-block !important;
        }

        .layout-#{$breakpoint}-d-block {
            display: block !important;
        }

        .layout-#{$breakpoint}-d-inline {
            display: inline !important;
        }

        .layout-#{$breakpoint}-d-none {
            display: none !important;
        }
    }
}
