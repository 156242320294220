@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';
@import '../../../shared/styles/mixins';
@import '../../../shared/styles/grid';

.container {
    margin: 0 auto;
    @include for-all-devices() {
        max-width: grid-item-width($current-grid-size, grid-var('columns'));
    }
    @media all and (min-width: $grid-width-limit) {
        max-width: grid-item-width($current-grid-size, grid-var('columns'), $grid-width-limit);
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;

    @include for-all-devices() {
        margin-right: -(grid-var('gap') / 2);
        margin-left: -(grid-var('gap') / 2);
        & > .item {
            margin: 0 (grid-var('gap') / 2) 0 (grid-var('gap') / 2);
        }
    }
}

.item {
    flex: 0 0 auto;
    @include for-all-devices() {
        @for $i from 1 through grid-var('columns') {
            &_#{$current-grid-size}--#{$i} {
                width: grid-item-width($current-grid-size, $i);
                @media all and (min-width: $grid-width-limit) {
                    width: grid-item-width($current-grid-size, $i, $grid-width-limit);
                }
            }
        }
    }
}
