@import '../../../../../../shared/styles/mixins';
@import '../../../../../../shared/styles/colors';

.avatarWrap {
    margin-left: 3rem;
    cursor: pointer;
}

.loginBtn {
    @include button();
    display: flex;
    flex-direction: row;
    align-items: center;
}

.loginIconWrap {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themed() {
        background: themed-color('text');
    }
}
