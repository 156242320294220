@import '../../../shared/styles/typography';
@import '../../../shared/styles/mixins';

.text {
    text-align: left;
    @include text-size-regular();
    @include text-color-text();

    &-size {
        &--small {
            @include text-size-small();
        }

        &--regular {
            @include text-size-regular();
        }

        &--large {
            @include text-size-large();
        }

        &--inherit {
            @include text-size-inherit();
        }
    }

    &-color {
        &--text-inverted {
            @include text-color-text-inverted();
        }

        &--label {
            @include text-color-label();
        }

        &--accent {
            @include text-color-accent();
        }

        &--fail {
            @include text-color-fail();
        }

        &--icon {
            @include text-color-icon();
        }

        &--inherit {
            @include text-color-inherit();
        }

        &--success {
            @include text-color-success();
        }
    }

    &-align {
        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }
    }

    &-wrap {
        @include text-break();
    }

    &-paragraph {
        padding-bottom: 4rem;
    }

    // wtf?
    span {
        a {
            @include styled-link();
            @include text-size-inherit();
        }
    }
}
