@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/easings';

.dropdown {
    overflow: hidden;
    min-width: 100%;
    position: absolute;
    display: inline-block;
    top: 100%;
    left: 0;
    overflow-y: auto;
    transform-origin: top;
    animation: selectReveal $durNorm $easeOutBack;
    animation-fill-mode: both;
    animation-play-state: running;
    z-index: 200;
    box-sizing: border-box;

    @include themed() {
        background: themed-color('input');
        border: 2px solid themed-color('border');
        border-top: 0;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    @include themed() {
        &::-webkit-scrollbar-thumb {
            background: themed-color('icon');
        }
    }
}

@keyframes selectReveal {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}
