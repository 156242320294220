@import '../../../../../shared/styles/colors';
@import '../../../../../shared/styles/easings';
@import '../../../../../shared/styles/mixins';
@import '../../../../../shared/styles/breakpoints';

.dropdownWrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    transform: translateY(-100%);
    transition: transform $durNorm ease;
    overflow-y: scroll;

    @include themed() {
        background-color: themed-color('background');
    }

    &.isOpened {
        transform: translateY(0%);
        transition: transform $durNorm ease;
    }
}

.gridContainer {
    height: 100%;
    max-height: calc(100% - 50px);
}

.dropdownHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    margin-bottom: 2rem;
    position: relative;

    @include themed() {
        background-color: themed-color('background');
    }
    z-index: 2;
}

.fakeDropdownHeader {
    background: transparent;
    height: 5rem;
    width: 100%;
    position: absolute;
    top: 2px;
    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }
    z-index: 1;
}

.guestActionBtn {
    @include button();
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdownContent {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dropdownFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.langSwitcherWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userBlock {
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
        margin-bottom: 4rem;
    }
}
