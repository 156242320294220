@import '../../../shared/styles/breakpoints';
@import '../../../shared/styles/colors';

.LightboxHeader {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    @include media-breakpoint-down(xs) {
        border: none !important;
        top: 15px;
        right: 15px;
    }
}

/**
 * @todo Избавиться от глобальных стилей
 * http://jossmac.github.io/react-images/#/styles
 */
:global {
    .react-images__view {
        align-items: center;
        display: flex;
        height: 100vh;
        justify-content: center;
        padding: 80px 0;
        @include media-breakpoint-down(xs) {
            padding: 50px 15px !important;
        }
    }

    .react-images__blanket {
        background-color: rgba(0, 0, 0, 0.85);
        @include media-breakpoint-down(xs) {
            @include themed() {
                background-color: themed-color('background') !important;
            }
        }
    }

    .react-images__positioner {
        display: block;
    }
}
