@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';

.root {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    vertical-align: middle;
}

.badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.8rem;
    height: 1.8rem;
    padding: 0.2rem;
    box-sizing: border-box;
    z-index: 1;

    @include suisse-intl();
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.invisible {
    opacity: 0;
}

.dot {
    min-width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 5px;
}

/**
 * Colors
 */
.color-accent {
    @include themed() {
        background-color: themed-color('accent');
        color: themed-color('text-inverted');
    }
}

.color-success {
    @include themed() {
        background-color: themed-color('success');
        color: themed-color('text-inverted');
    }
}

.color-warning {
    @include themed() {
        background-color: themed-color('warning');
        color: themed-color('text-inverted');
    }
}

.color-fail {
    @include themed() {
        background-color: themed-color('fail');
        color: themed-color('text-inverted');
    }
}

/**
 * Position
 */
.position-top-left-rectangle {
    top: 0;
    left: 0;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0 0;
}

.position-top-left-circle {
    top: 14%;
    left: 14%;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0 0;
}

.position-top-center-rectangle,
.position-top-center-circle {
    top: 0;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0 0;
}

.position-top-right-rectangle {
    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0;
}

.position-top-right-circle {
    top: 14%;
    right: 14%;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0;
}

.position-middle-right-rectangle,
.position-middle-right-circle {
    top: 50%;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0;
}

.position-bottom-right-rectangle {
    bottom: 0;
    right: 0;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;
}

.position-bottom-right-circle {
    bottom: 14%;
    right: 14%;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;
}

.position-bottom-center-rectangle,
.position-bottom-center-circle {
    bottom: 0;
    left: 50%;
    transform: scale(1) translate(-50%, 50%);
    transform-origin: 100% 100%;
}

.position-bottom-left-rectangle {
    bottom: 0;
    left: 0;
    transform: scale(1) translate(-50%, 50%);
    transform-origin: 100% 100%;
}

.position-bottom-left-circle {
    bottom: 14%;
    left: 14%;
    transform: scale(1) translate(-50%, 50%);
    transform-origin: 100% 100%;
}

.position-middle-left-rectangle,
.position-middle-left-circle {
    top: 50%;
    left: 0;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 100% 100%;
}
