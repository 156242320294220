@import '../../../shared/styles/colors';

.photo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background: transparent;

    .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include themed() {
            background-color: themed-color('background');
        }
    }

    &.clickable {
        cursor: pointer;
    }

    .uploadIconWrap {
        width: 6rem;
        height: 6rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    &.hasError {
        @include themed() {
            border-bottom: 2px solid themed-color('fail');
        }
    }
}

.containerWrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    &_short {
    }
}

.containerContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.iconWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4rem;

    .reloadWrap,
    .closeWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    }

    .reloadWrap {
        @include themed() {
            background: themed-color('accent');
        }
    }

    .closeWrap {
        @include themed() {
            background: themed-color('text');
        }
    }
}

.onDragActive {
    @include themed() {
        outline: 2px solid themed-color('accent');
    }
}

.uploadInput {
    width: 100%;
}
