@import '../../../shared/styles/_typography.scss';
@import '../../../shared/styles/_colors.scss';
@import '../../../shared/styles/_breakpoints.scss';

// rfs
$rfs-breakpoint: 1920;
$rfs-mode: max-media-query;
$rfs-rem-value: 10;
@import '~rfs/scss';

.pageError {
    width: 100%;
    min-height: 100vh;

    @include themed() {
        background: themed-color('background');
    }
}

.image {
    position: absolute;
    top: 5rem;
    left: 50%;
    @include rfs(87.5rem, width);
    @include rfs(64.2rem, height);
    background: url('./img/background.png') center center / cover no-repeat;

    @include media-breakpoint-only(lg) {
        left: calc(50% - 4rem);
    }

    @include media-breakpoint-down(sm) {
        position: unset;
        top: unset;
        right: unset;
        @include rfs(95rem, width);
        @include rfs(70rem, height);
    }
}

.button {
    margin-top: 4rem;
    width: 34.4rem;
    height: 6rem;
    font-weight: normal;
    @include media-breakpoint-down(sm) {
        margin-top: 3rem;
    }
    @include media-breakpoint-only(xs) {
        width: 100%;
        min-width: 26rem;
        padding: 0 1rem;
    }
}
