@import '../../../shared/styles/breakpoints';
@import '../../../shared/styles/typography';

.LightboxFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    position: absolute;
    @include text-size-small();
    @include text-color-text-inverted();
    @include media-breakpoint-down(xs) {
        bottom: 15px;
        @include text-color-label();
    }
}
