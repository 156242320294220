@import '../../../../../shared/styles/colors';

.link {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.active {
    @include themed() {
        color: themed-color('accent');
    }
}

.heading {
    text-transform: capitalize;
}
