@import '../../../shared/styles/breakpoints';

.skeletonParagraph {
    height: 2rem;
    margin-bottom: 1rem;

    @include media-breakpoint-only(sm) {
        height: 1.5rem;
    }

    &:first-of-type {
        margin-left: auto;
        width: calc(100% - 6rem);

        @include media-breakpoint-only(sm) {
            width: calc(100% - 3rem);
        }
    }

    &:last-of-type {
        width: 50%;
        margin-bottom: 0;
        margin-right: auto;

        @include media-breakpoint-only(sm) {
            width: 40%;
        }
    }
}
