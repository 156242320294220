@import '../../../../shared/styles/typography';

.MenuItem {
    @include text-size-small();
    @include text-color-label();

    &_clickable {
        cursor: pointer;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
