@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/grid';
@import '../../../shared/styles/mixins';

@mixin avatarSize($size, $fontSize) {
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;

    .image {
        height: $size;
    }

    .firstLetter {
        font-size: $fontSize;
    }
}

@mixin avatarSizeStandard() {
    @include avatarSize(40px, 1.8rem);
}

@mixin avatarSizeSmall() {
    @include avatarSize(20px, 1.2rem);
}

@mixin avatarSizeLarge() {
    @include avatarSize(60px, 2.4rem);
}

.avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include themed() {
        background: themed-color('border');
        color: themed-color('label');
    }

    &.isClickable {
        cursor: pointer;
        @include focus-outline();
    }

    &.size--standard {
        @include avatarSizeStandard();
    }

    &.size--small {
        @include avatarSizeSmall();
    }

    &.size--large {
        @include avatarSizeLarge();
    }

    @include for-all-devices() {
        &.size-#{$current-grid-size}--standard {
            @include avatarSizeStandard();
        }
    }

    @include for-all-devices() {
        &.size-#{$current-grid-size}--small {
            @include avatarSizeSmall();
        }
    }
}

.image {
    display: block;
    object-fit: cover;
    width: 100%;
}

.online {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 6px;

    @include themed() {
        background: themed-color('success');
        border-top: 2px solid themed-color('background');
        border-left: 2px solid themed-color('background');
    }
}

.isDisabled {
    opacity: 0.3;
}
