@import '../../../../shared/styles/typography';
@import '../../../../shared/styles/colors';
@import '../../../../shared/styles/easings';
@import '../../../../shared/styles/mixins';

$headerMobileHeight: 5rem;

.header {
    height: $headerMobileHeight;
    box-sizing: border-box;
    width: 100%;

    @include themed() {
        background-color: themed-color('background');
        box-shadow: inset 0px -2px 0px 0px themed-color('border');
    }
}

.headerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: $headerMobileHeight;
    height: $headerMobileHeight;

    @include themed() {
        background-color: themed-color('text');
    }
}

.siteName {
    margin-left: 1.5rem;
    font-style: normal;

    &.beta {
        position: relative;
        margin-right: 1.7rem;

        &:before {
            content: '';
            position: absolute;
            top: -0.4rem;
            right: -1.7rem;
            background: url('../../../../assets/beta.svg') center center / cover no-repeat;
            width: 2.4rem;
            height: 2.4rem;
            transform: rotate(-30deg);
        }
    }
}

.controls {
    display: flex;
}

.controlWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.burgerButton {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    @include button();

    &.isOpened {
        & .burgerLine:first-child {
            transform: translateY(4.5px) rotate(45deg);
        }
        & .burgerLine:last-child {
            transform: translateY(-4.5px) rotate(-45deg);
        }
    }
}

.burgerLine {
    width: 20px;
    height: 3px;
    transition: transform $easeOutBack $durNorm;

    @include themed() {
        background-color: themed-color('text');
    }

    &:first-child {
        margin-top: 4px;
        margin-bottom: 6px;
    }
}

.controlItem {
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    margin-right: -0.5rem;
    @include focus-outline();
}

.notificationsWrapper {
    margin-right: 1rem;
}
