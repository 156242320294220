@import '../../../shared/styles/typography';
@import '../../../shared/styles/colors';
@import '../../../shared/styles/mixins';

.root {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    width: fit-content;

    &.isClickable {
        @include button();
    }

    &.isActive {
        @include text-color-accent();
    }
}

.counter {
    display: block;
    margin-left: 8px;

    @include suisse-intl();
    @include text-color-label();
}
