@import '../../shared/styles/colors';

.Content {
    :global {
        .cdx-marker,
        .markedText > mark {
            @include themed() {
                background: themed-color('notification-info');
                color: inherit;
            }
        }
    }

    span a {
        @include themed() {
            color: themed-color('accent');
        }
    }
}
